import { useSelectCountryMainDomain } from '@entities/countries';
import { htmlParser, imageReplacer, IImgProps } from '@shared/lib/html';

const parseDescription = ({
  description,
  mainDomain,
}: {
  description: string;
  mainDomain: string;
}) => {
  try {
    const parsed = htmlParser<IImgProps>(description, {
      from: 'img',
      to: (props) =>
        imageReplacer({
          ...props,
          alt: props.alt || 'action image',
          width: 740,
          height: 490,
          mainDomain,
        }),
    });
    return parsed;
  } catch (e) {
    console.error(e);
    return null;
  }
};

function Description({ description }: { description: string }) {
  const mainDomain = useSelectCountryMainDomain();
  return <>{parseDescription({ description, mainDomain })}</>;
}

export default Description;
