import { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import * as session from 'modules/session';
import globalStore from 'modules/global-store';
import productHelper from 'modules/helpers/product-helper';
import { useTranslation } from 'react-i18next';
import { getProductIds } from 'modules/helpers/cart-helper';
import api from 'modules/helpers/api';
import { userEmitter } from 'modules/event-emitters';
import { isEmpty } from 'lodash';
import { useCurrency } from '@entities/countries';
import { Complectation } from '@entities/complectation';
import type { ICart } from 'types/cartTypes';
import Emitter from 'src/frontend/Components/NewCartOrder/Emitters';
import { BlockContainer } from '@shared/ui/content-containers';
import { Subtitle } from '@shared/ui/titles';
import { clsx } from 'clsx';
import { CartHeader } from 'frontend/Pages/cart/ui/header/cart-header';
import { useComplectationInfoModal } from '@features/modals/comlectation-info';
import { useCityTextIdParam } from '@shared/lib/routing';
import { useErrorCartModal } from '@features/modals/cart-error-modal';
import CartProducts from './CartProducts/CartProducts';
import CartGifts from './CartGifts/CartGifts';
import RecommendedProducts from './RecommendedProducts/RecommendedProducts';
import UserGifts from '../../../UserGifts/UserGifts';
import styles from './blockProducts.module.scss';
import CartAdditionals from './CartAdditionals/CartAdditionals';

function CartProductsBlock() {
  const { t } = useTranslation();
  const { isExact } = useRouteMatch();
  const cart: ICart = session.get('cart');
  const [isLogin, setIsLogin] = useState(() => {
    const { user } = session.get() || {};
    return !isEmpty(user);
  });
  const [isNotEmptyGifts, setIsNotEmptyGifts] = useState(true);
  const currency = useCurrency();
  const history = useHistory();
  const errorModal = useErrorCartModal();
  const cityTextId = useCityTextIdParam();
  const additional = globalStore.get('additionally');
  const { items = [], total, total_orig } = cart;

  const isProducts = !!items.filter(
    (item) => !!productHelper.getCartProduct(item.product_id)
  ).length;
  const isDiscount = total < total_orig;
  const isAdditional = additional && additional.length > 0;
  const isShowUserGifts = isLogin && isNotEmptyGifts;
  useEffect(() => {
    const onCartChange = (args: { notChangeUrl?: boolean }) => {
      const { notChangeUrl = false } = args || {};
      if (isExact || notChangeUrl) {
        return;
      }
      history.push(`/${cityTextId}/cart`);
    };

    const setUserIsLogin = async () => {
      const ids = getProductIds(session.get('cart'));
      const products = await api('product.get', { id: ids });
      globalStore.set('cart_products', products);
      setIsLogin(true);
    };

    Emitter.addListener('CART_CHANGE', onCartChange);
    userEmitter.addListener('User.Login.Event', setUserIsLogin);
    return () => {
      Emitter.removeListener('CART_CHANGE', onCartChange);
      userEmitter.removeListener('User.Login.Event', setUserIsLogin);
    };
  }, [history, isExact]);
  const complectationInfoModal = useComplectationInfoModal();

  return (
    <>
      {isProducts && (
        <BlockContainer>
          <CartHeader />
          <CartProducts {...{ isExact }} />
        </BlockContainer>
      )}
      <Complectation
        isExact={isExact}
        infoBtnAction={complectationInfoModal.show}
        onError={(text: string) => errorModal.show({ text })}
      />
      {isShowUserGifts && (
        <BlockContainer>
          <Subtitle>
            {!isExact
              ? t('BlockProducts.your_user_gift')
              : t('BlockProducts.add_gifts')}
          </Subtitle>
          <UserGifts
            isExact={isExact}
            setIsNotEmptyUserGifts={setIsNotEmptyGifts}
          />
        </BlockContainer>
      )}
      <CartGifts isExact={isExact} />
      {isAdditional && isExact && (
        <BlockContainer>
          <Subtitle>{t('BlockProducts.your_additional')}</Subtitle>
          <CartAdditionals additional={additional} />
        </BlockContainer>
      )}
      <RecommendedProducts />
      <div className={clsx(styles.total, { [styles.pale]: !isExact })}>
        <p>
          {isExact ? t('BlockProducts.total_order') : t('BlockProducts.cart')}
        </p>
        <p>
          <strong>
            <span>
              {total}&nbsp;{currency}
            </span>
            {isDiscount && (
              <small>
                {total_orig}&nbsp;{currency}
              </small>
            )}
          </strong>
        </p>
      </div>
    </>
  );
}

export default CartProductsBlock;
