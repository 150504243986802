import { useLanguage } from '../model/language-handler';
import styles from './selectLanguage.module.scss';
import { getDisplayedLanguage } from '../lib/get-displayed-language';

export function SelectLanguage({
  cityAvailableLangs,
}: {
  cityAvailableLangs?: string[];
}) {
  const { availableLangs, currentLanguage, changeLanguage } =
    useLanguage(cityAvailableLangs);
  return (
    <div className={styles.wrapper}>
      <select
        value={currentLanguage}
        className={styles.lang}
        onChange={(e) => changeLanguage(e.target.value)}
      >
        {availableLangs.map((lang) => {
          return (
            <option key={lang} value={lang}>
              {getDisplayedLanguage(lang)}
            </option>
          );
        })}
      </select>
    </div>
  );
}
