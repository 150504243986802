export const modalIds = {
  SelectCityModalId: 'select-city',
  ConfirmCityModalId: 'confirm-city',
  DownloadAppsModalId: 'download-apps',
  SelectAddressModalId: 'select-address-modal',
  complectationInfoModalId: 'complectation-info-modal',
  forgetComplectationModalId: 'forget-complectation-modal',
  ModalPageModalId: 'template-info-modal',
  VideoModal: 'video-modal',
  mobileSidebarModalId: 'mobile-sidebar-modal',
  cartErrorModalId: 'cart-error-modal',
  uaPickupErrorModalId: 'ua-pickup-error-modal',
  cartPromocodeErrorModalId: 'cart-promocode-error-modal',
};
