import './PickupMap.scss';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BallonImage from 'assets/images/icon/ballon-icon.svg';
import UaLocationIcon from 'assets/images/icon/uaLocationIcon.svg';
import { useCheckCountries, useSelectCountry } from '@entities/countries';
import { Suggest } from '@features/address-suggest';
import { PickupMap as PickupMapWrapper } from '@features/stores-map';
import { IStore, storeHelper, useGetStoresListQuery } from '@entities/stores';
import { useSelectedStoreId } from '@entities/pickup';
import { useCityAndLang } from '@shared/lib/use-city-and-lang';
import { isAddressError, useGetAddressQuery } from '@entities/delivery';
import { useSelectCity } from '@entities/cities';
import { skipToken } from '@reduxjs/toolkit/query';
import { scrollToExecution } from 'frontend/Components/NewCartOrder/Helpers';
import { getMapType } from '@shared/third-party-libs/commonMap';
import styles from './balloonContent.module.scss';

function PickupMap({
  onSelectStore,
}: {
  onSelectStore: (store: IStore) => void;
}) {
  const { isUkraine } = useCheckCountries();
  const { t } = useTranslation();
  const selectedStoreId = useSelectedStoreId();

  const [currentStoreId, setCurrentStoreId] = useState<number | null>(
    selectedStoreId
  );

  const { map_type: mapTypeByCountry, main_domain } = useSelectCountry();

  const mapContaonerRef = useRef(null);
  useEffect(() => {
    if (currentStoreId && mapContaonerRef) {
      scrollToExecution(mapContaonerRef.current, 100);
    }
  }, [mapContaonerRef, currentStoreId]);

  const { city, lang } = useCityAndLang();

  const {
    city_name: cityName,
    latitude: lat,
    longitude: lng,
    map_type: mapTypeByCity,
  } = useSelectCity();

  const mapTypes = getMapType({ mapTypeByCity, mapTypeByCountry });

  const [newAddress, setNewAddress] = useState<string | number[] | null>(null);

  const params = newAddress
    ? { coordsOrString: newAddress, cityName, cityTextId: city, lang }
    : skipToken;
  const { data: fullAddress = null } = useGetAddressQuery(params);

  const setAddress = (address: string) => {
    setNewAddress(address);
  };

  const setCurrentCenter = (coors: number[] | null) => {
    setNewAddress(coors);
  };

  const { data: stores } = useGetStoresListQuery({ city, lang });

  const LocatioIcon = isUkraine ? UaLocationIcon : BallonImage;

  const currentStore = stores?.find((store) => store.id === currentStoreId);
  const { infoText, buttonText } = storeHelper.getPickupButtonTexts({
    t,
    store: currentStore,
  });

  const additional = infoText ? (
    <p className={styles.infoWork}>{infoText}</p>
  ) : null;

  const newFullAddress = isAddressError(fullAddress) ? null : fullAddress;
  return (
    <div className="pickup-map">
      <div className="pickup-map__toolbar">
        <div className="pickup-map__toolbar__left">
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
          <div
            className="geolocation-wrap"
            role="button"
            tabIndex={0}
            // onClick={getPosition}
            style={{ display: 'inline-block' }}
          >
            <LocatioIcon />
          </div>
          <div className="pickup-map__suggest">
            <div className="pickup-map__label">
              {t('PickupMap.your_address')}
            </div>
            <Suggest
              placeholder={t('List.Enter street or subway')}
              fullAddress={newFullAddress}
              setAddress={setAddress}
              cityCoords={{ lat, lng }}
              cityName={cityName}
              lang={lang}
            />
          </div>
        </div>
      </div>
      <div ref={mapContaonerRef} className="pickup-map__content">
        <PickupMapWrapper
          stores={stores || []}
          actionFn={onSelectStore}
          fullAddress={newFullAddress}
          setCurrentCenter={setCurrentCenter}
          cityCoords={{ lat, lng }}
          currentStoreId={currentStoreId}
          setCurrentStoreId={setCurrentStoreId}
          btnText={buttonText}
          additional={additional}
          mapTypes={mapTypes}
          isUkraine={isUkraine}
          mainDomain={main_domain}
        />
      </div>
    </div>
  );
}

export default PickupMap;
