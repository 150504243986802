import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from 'modules/helpers/api';
import { afterCartItem } from 'modules/helpers/cart-helper';
import { t } from 'i18next'; // удалить, испльзовать через хук
import type { ICart, ICartItem } from 'types/cartTypes';
import { AppThunkDispatch } from '@shared/lib/store';
import {
  IComplectationFree,
  IComplectationItem,
  IComplectationPaid,
  IComplectationState,
} from '../types';

const paidItemsSender = async (
  toSendItems: { id: number; qty: number }[],
  onError: (text: string) => void
) => {
  try {
    const newCart: ICart = await api('cart.items', toSendItems);
    await afterCartItem({ newCart, t, onError });
    return newCart;
  } catch (e) {
    throw Error(String(e));
  }
};

export const sortCartComplectation = ({
  items,
  compItems,
}: {
  items: ICartItem[];
  compItems: IComplectationItem[];
}) => {
  const paid = compItems
    .sort((a, b) => a.position - b.position)
    .map<IComplectationPaid>(({ id }) => {
      const compInCart = items.find(({ id: cartItemId }) => id === cartItemId);
      return { product_id: id, qty: compInCart?.qty ?? 0 };
    });

  return paid;
};

const initState: IComplectationState = {
  free: [],
  paid: [],
  isLoading: false,
};

export const addPaid = createAsyncThunk<
  IComplectationPaid[],
  { toSendItems: IComplectationPaid[]; onError: (text: string) => void },
  { dispatch: AppThunkDispatch }
>('complectation/addPaid', async ({ toSendItems, onError }) => {
  const sendItems = toSendItems.map(({ product_id, qty }) => {
    return { id: product_id, qty };
  });
  const { items, complectations } = await paidItemsSender(sendItems, onError);
  if (!complectations || !complectations.length) {
    return [];
  }
  const paid = sortCartComplectation({
    items,
    compItems: complectations,
  });
  return paid;
});

export const deleteAllPaid = createAsyncThunk<
  IComplectationPaid[],
  (text: string) => void,
  { dispatch: AppThunkDispatch }
>('complectation/deleteAllPaid', async (onError, thunkApi) => {
  const {
    complectation: { paid: currentPaid },
  } = thunkApi.getState() as RootState;
  const deleteItems = currentPaid.map(({ product_id, qty }) => {
    return { id: product_id, qty: -qty };
  });
  if (!deleteItems.length) return [];
  const { items, complectations } = await paidItemsSender(deleteItems, onError);
  if (!complectations || !complectations.length) {
    return [];
  }
  const paid = sortCartComplectation({
    items,
    compItems: complectations,
  });
  return paid;
});

export const complectationSlice = createSlice({
  name: 'complectation',
  initialState: initState,
  reducers: {
    addFree: (state, action: PayloadAction<{ id: number; qty: number }>) => {
      const { free } = state;
      const { id, qty } = action.payload;
      const newFree = free.map((item) => {
        if (item.product_id === id) return { ...item, qty: item.qty + qty };
        return item;
      });
      state.free = newFree;
    },
    removeFreeItems: (state, action: PayloadAction<number[]>) => {
      const newFree = state.free.map((item) => {
        if (action.payload.includes(item.product_id)) {
          return { ...item, qty: 0 };
        }
        return item;
      });
      state.free = newFree;
    },
    setFree: (state, action: PayloadAction<IComplectationFree[]>) => {
      state.free = action.payload;
    },
    setPaid: (state, action: PayloadAction<IComplectationPaid[]>) => {
      state.paid = action.payload;
    },
    resetComplectation: (state) => {
      state.free = [];
      state.paid = [];
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addPaid.fulfilled, (state, action) => {
      state.paid = action.payload;
      state.isLoading = false;
    });
    builder.addCase(addPaid.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addPaid.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteAllPaid.fulfilled, (state, action) => {
      state.paid = action.payload;
      state.isLoading = false;
    });
    builder.addCase(deleteAllPaid.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteAllPaid.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

const selectComplectationState = (state: RootState) => {
  return state.complectation;
};

const selectFree = (state: RootState) => {
  return state.complectation.free;
};

const selectPaid = (state: RootState) => {
  return state.complectation.paid;
};

const selectIsLoading = (state: RootState) => {
  return state.complectation.isLoading;
};

export const complectationSliceSelectors = {
  selectComplectationState,
  selectFree,
  selectPaid,
  selectIsLoading,
};

export const {
  addFree,
  removeFreeItems,
  setFree,
  setPaid,
  resetComplectation,
} = complectationSlice.actions;
