import { SmartLink } from '@shared/lib/routing';
import { ExternalImage } from '@shared/ui/image';
import { useTranslation } from 'react-i18next';
import { useSelectCountryMainDomain } from '@entities/countries';
import { IMineSlider } from '../model/types';
import { useSliderItemUrl } from '../model/useSliderItemUrl';

export function SliderItem({ slide }: { slide: IMineSlider }) {
  const { t } = useTranslation();
  const mainDomain = useSelectCountryMainDomain();
  const { image } = slide;
  const url = useSliderItemUrl(slide);

  const sizes = {
    mobile: {
      width: 410,
      height: 410,
    },
    tablet: {
      width: 410,
      height: 410,
    },
    desktop: {
      width: 600,
      height: 600,
    },
  };

  const sliderImage = (
    <ExternalImage
      mainDomain={mainDomain}
      src={image}
      alt={t('Main.slide')}
      visibleByDefault
      width={600}
      height={600}
      sizes={sizes}
    />
  );

  return url ? <SmartLink href={url}>{sliderImage}</SmartLink> : sliderImage;
}
