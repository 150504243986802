import { PropsWithChildren } from 'react';
import { CloseButton } from '@shared/ui/close-button';
import { useOutsideClick } from '@shared/lib/use-outside-click';
import { clsx } from 'clsx';
import { Dialog } from '../dialog/dialog';
import styles from './popup.module.scss';
import commonContentStyles from '../common-content.module.scss';

interface IPopup {
  onClose: () => void;
  isOpen: boolean;
}

export function Popup({
  isOpen,
  children,
  onClose,
}: PropsWithChildren<IPopup>) {
  const { outsideClickRef } = useOutsideClick(onClose);
  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <div
        ref={outsideClickRef}
        className={clsx(commonContentStyles.commonContent, styles.content)}
      >
        <CloseButton onClick={onClose} />
        {children}
      </div>
    </Dialog>
  );
}
