import { ISeoPage } from '@widgets/seoPage';
import type { FetchLoaderType } from '@shared/lib/routing';

type Params = {
  city: string;
  pageId: string;
};
export async function MenuLoader(
  fetch: FetchLoaderType,
  { params, lang }: { params: Params; lang: string }
) {
  const { city } = params;

  const seoPageResponse = await fetch<ISeoPage>({
    url: '/seo/page',
    params: { url: `/menu`, city, lang },
  });

  return {
    seoPageData: seoPageResponse.data,
  };
}
