import { ExternalImage } from '@shared/ui/image';

export interface IImgProps {
  mainDomain: string;
  alt: string;
  src: string;
  width: number;
  height: number;
  className?: string;
}

export const imageReplacer = ({
  mainDomain,
  alt,
  src,
  width,
  height,
  className,
}: IImgProps) => {
  return (
    <ExternalImage
      mainDomain={mainDomain}
      alt={alt || ''}
      src={src.replace('/img', '')}
      width={width}
      height={height}
      className={className}
    />
  );
};
