import Emitters from 'frontend/Components/NewCartOrder/Emitters';
import { getItemsDisablesForOrderType } from 'frontend/Components/NewCartOrder/Helpers';

export const loginHandler = (handleAfterModal: (success: boolean) => void) => {
  Emitters.emit('MODAL_CALL', {
    modal: 'RegisterOrAuthModal',
    info: 'default',
    value: { handleAfterModal },
  });
};

export const deleteProductsHandler = (
  isDelivery: boolean,
  handleAfterModal: (success: boolean) => void
) => {
  Emitters.emit('MODAL_CALL', {
    modal: 'DisabledProductsModal',
    value: {
      product_ids: getItemsDisablesForOrderType(isDelivery),
      isDelivery,
      handleAfterModal,
    },
  });
};
