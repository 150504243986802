import './ArticleCard.scss';
import { Component } from 'react';
import { formatDateWithLocale } from '@shared/lib/date';
import { ExternalImage } from '@shared/ui/image';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { SmartLink } from '@shared/lib/routing';
import SocialElements from '../../../Components/SocialElements/SocialElements';

class ArticleCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  truncate = ({ string, length, isCrop }) => {
    if (isCrop && string.length > length)
      return `${string.slice(0, length)}...`;
    return string;
  };

  render() {
    const { isHover } = this.state;
    const {
      i18n: { language, t },
      mainDomain,
    } = this.props;
    const {
      announcement_text,
      name,
      category_name,
      title = '',
      images,
      publication_date,
      publication_from,
      likes,
      id,
      text_id,
      pathname,
      type,
    } = this.props;
    const src = `/img/${images[0]?.filename}`;
    const link = `${pathname}${text_id}`;
    const shortYear = t('locale.year');
    return (
      <SmartLink
        className="articles-card"
        href={`/${type}/${text_id}`}
        onMouseEnter={() => this.setState({ isHover: true })}
        onMouseLeave={() => this.setState({ isHover: false })}
      >
        <div className="articles-card-wrapper">
          <div className="articles-img-container">
            <div className="articles-img-center">
              <ExternalImage
                mainDomain={mainDomain}
                src={images[0]?.filename}
                alt={title || name}
                width={743}
                height={820}
              />
            </div>
          </div>
          <div className="articles-text-container">
            <span className="articles-info-container">
              <strong>
                {formatDateWithLocale(
                  publication_date || publication_from,
                  `d MMMM yyyy '${shortYear}'. HH:mm`,
                  language
                )}
              </strong>
              <strong className="articles-title">{category_name}</strong>
            </span>
            <h3>{title || name}</h3>
            <p>
              {this.truncate({
                string: announcement_text,
                length: 150,
                isCrop: !isHover,
              })}
            </p>
          </div>
        </div>
        <SocialElements
          link={link}
          title={title}
          image={src}
          type={type}
          description={announcement_text}
          id={id}
          likes={likes}
        />
      </SmartLink>
    );
  }
}
const mapStateToProps = ({ countries }) => ({
  mainDomain: countries.country.main_domain,
});

export default connect(mapStateToProps)(withTranslation()(ArticleCard));
