import Template from 'frontend/Pages/Template/Template';
import { checkCountries, useSelectCountry } from '@entities/countries';
import { Header } from '@widgets/header';
import { Footer } from '@widgets/footer';
import {
  IRoute,
  RoutesWithSubRoutes,
  CityLayoutContext,
  useLoaderData,
} from '@shared/lib/routing';
import { useResetCartOnChangeCity } from '@features/cart-reset';
import { useCartQtyHandler } from '@features/add-product-to-cart/useCartQtyHandler';
import { useSelectAddressFirst } from '@features/modals/select-address-first';
import { useCityChange, useSelectCity } from '@entities/cities';
import { ConfirmCity } from '@features/modals/confirm-city-modal';
import { useSelectCityModal } from '@features/modals/select-city-modal';
import SupportChat from '@widgets/support-chat/SupportChat';
import Cookie from '@widgets/cookie/Cookie';
import { SidebarDesktop } from '@widgets/sideBar';
import { DesktopMenu, useSelectMenu } from '@entities/menu';
import { useMemo, useCallback } from 'react';
import { ModalPageInfo } from '@features/modals/modal-page';
import { YMapsProvider } from '../providers/ymaps-provider';
import { ModalProvider } from '../providers/modal-provider';
import { AppHelmet, CountryHelmet } from '../helmet';
import TemplateHelmet from '../helmet/TemplateHelmet';
import { cityLayoutLoader } from './api';
// import Hamster from 'frontend/@widgets/hamster/Hamster';

export function CityLayout({ routes }: { routes: IRoute[] }) {
  const { pageData } =
    useLoaderData<Awaited<ReturnType<typeof cityLayoutLoader>>>();
  const city = useSelectCity();
  const country = useSelectCountry();
  const { hasCityChanged } = useCityChange(city.text_id);
  useResetCartOnChangeCity(hasCityChanged);
  const { show: showSelectCityModal } = useSelectCityModal();
  const showSelectCity = useCallback(() => {
    showSelectCityModal({ cityTextId: city.text_id, country });
  }, [city.text_id, country, showSelectCityModal]);

  useCartQtyHandler();
  useSelectAddressFirst(city.show_delivery_pickup_popup);
  const isCheckCountries = checkCountries(country.alpha2);
  const menu = useSelectMenu();
  const cityContextValue = useMemo(
    () => ({ city, country, menu }),
    [city, country, menu]
  );
  return (
    <YMapsProvider
      mapTypeByCity={city.map_type}
      mapTypeByCountry={country.map_type}
    >
      <ModalProvider>
        <AppHelmet
          mainDomain={country.main_domain}
          formatLocale={city.format_locale}
          countryName={country.name}
          cityName={city.city_name}
          cityPhone={city.phone}
          cityTextId={city.text_id}
          worktimeFrom={city.info.worktime.from}
          worktimeTo={city.info.worktime.to}
        />

        <CountryHelmet {...isCheckCountries} />
        <ModalPageInfo cityTextId={city.text_id} />
        <TemplateHelmet />
        <Header
          menuList={menu}
          countryId={country.id}
          isCheckCountries={isCheckCountries}
          cityName={city.city_name}
          cityPhone={city.phone}
          cityAvailableLangs={city.available_langs}
          mainDomain={country.main_domain}
          showSelectCity={showSelectCity}
          headerMenu={pageData.headerMenuData}
        />
        <SidebarDesktop>
          <DesktopMenu mainDomain={country.main_domain} menuList={menu} />
        </SidebarDesktop>
        <CityLayoutContext.Provider value={cityContextValue}>
          <RoutesWithSubRoutes routes={routes} />
        </CityLayoutContext.Provider>
        <Footer
          cityPhone={city.phone}
          isCallBack={city.is_call_back}
          deliveryFrom={city.info.delivery.from}
          deliveryTo={city.info.delivery.to}
          worktimeFrom={city.info.worktime.from}
          worktimeTo={city.info.worktime.to}
          emailgate={city.emailgate || ''}
          cityTextId={city.text_id}
          footerLinks={pageData.footerLinksData}
          socialLinks={pageData.socialData}
          {...isCheckCountries}
        />
        <ConfirmCity
          countryId={country.id}
          countryName={country.name}
          cityName={city.city_name}
          cityTextId={city.text_id}
          showSelectCity={showSelectCity}
        />
        <Template />
        {/* <Hamster /> */}
        <Cookie />
        {city.chat_enabled && <SupportChat {...isCheckCountries} />}
      </ModalProvider>
    </YMapsProvider>
  );
}
