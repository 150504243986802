import type { FetchLoaderType } from '@shared/lib/routing';
import { storeHelper } from '@entities/stores';
import { IStoresFromApi } from '@entities/stores/model/types';
import { ISeoPage } from '@widgets/seoPage';

type Params = {
  city: string;
  pageId: string;
};
export const storesPageLoader = async (
  fetch: FetchLoaderType,
  { params, lang }: { params: Params; lang: string }
) => {
  const { city } = params;
  const storesListResponse = fetch<{ stores: IStoresFromApi[] }>({
    url: '/store/get_list_city',
    params: { city, lang },
  });
  const seoPageResponse = fetch<ISeoPage>({
    url: '/seo/page',
    params: { url: `/addresses`, city, lang },
  });
  const [storesList, seoPage] = await Promise.all([
    storesListResponse,
    seoPageResponse,
  ]);
  return {
    stores: storeHelper.flatMetro(storesList.data.stores),
    seoPageData: seoPage.data,
  };
};
