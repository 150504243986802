import styles from './mobile.module.scss';
import { CartLink } from '../cartLink/CartLink';

interface IMobileProps {
  mobileSidebarOpener: JSX.Element;
  decoration: { left?: JSX.Element; right?: JSX.Element };
  logo: JSX.Element;
}

function Mobile({ mobileSidebarOpener, decoration, logo }: IMobileProps) {
  return (
    <div className={styles.root}>
      {mobileSidebarOpener}
      {decoration.left}
      {logo}
      {decoration.right}
      <CartLink isMobile />
    </div>
  );
}

export { Mobile };
