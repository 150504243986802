import { useSelectCity } from '@entities/cities';
import { useSelectCountry } from '@entities/countries';

const yandexMapCode = 1;
const googleMapCode = 2;

export const useMapType = () => {
  const { map_type: mapTypeByCity } = useSelectCity();
  const { map_type: mapTypeByCountry } = useSelectCountry();
  const mapType = mapTypeByCity || mapTypeByCountry;

  return {
    isYandexMap: mapType === yandexMapCode,
    isGoogleMap: mapType === googleMapCode,
  };
};

export const getMapType = ({
  mapTypeByCity,
  mapTypeByCountry,
}: {
  mapTypeByCity: number | null;
  mapTypeByCountry: number | null;
}) => {
  const mapType = mapTypeByCity || mapTypeByCountry;

  return {
    isYandexMap: mapType === yandexMapCode,
    isGoogleMap: mapType === googleMapCode,
  };
};
