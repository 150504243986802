import { ISeoPage } from '@widgets/seoPage';
import type { FetchLoaderType } from '@shared/lib/routing';

type Params = {
  city: string;
  vacancies_item: string;
};
export const vacancyLoader = async (
  fetch: FetchLoaderType,
  { params, lang }: { params: Params; lang: string }
) => {
  const { city, vacancies_item } = params;

  const [seoPageResponse, vacancyResponse] = await Promise.all([
    fetch<ISeoPage>({
      url: '/seo/page',
      params: { url: `/vakansii/${vacancies_item}`, city, lang },
    }),
    fetch({
      url: '/vacancies/get',
      params: { city, lang, text_id: vacancies_item },
    }),
  ]);
  return {
    seoPageData: seoPageResponse.data,
    vacancyData: vacancyResponse.data,
  };
};
