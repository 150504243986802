import { useEffect, useState } from 'react';
import { useYMaps } from '@pbe/react-yandex-maps';
import { renderToStaticMarkup } from 'react-dom/server';
import { UseStoresType } from '../commonMap/ui/PickupMap';
import { BallonLayout } from './ui/BalloonLayout';
import { onBalloonClick } from '../commonMap/lib';

// Latitude shift for store popup (map center shift)
const popupLatitudeShift = 0.0065;

const libs = [
  'Map',
  'control.ZoomControl',
  'templateLayoutFactory',
  'GeoObjectCollection',
  'geoQuery',
  'Balloon',
  'route',
  'Placemark',
];
const behaviors = ['drag', 'multiTouch', 'dblClickZoom', 'scrollZoom'];
const zoom = 10;
const controls = ['zoomControl'];

const options = {
  suppressMapOpenBlock: true,
  maxZoom: 18,
  yandexMapDisablePoiInteractivity: true,
};

interface IGeoQueryResult {
  getClosestTo: (coordinates: number[]) => ymaps.Placemark | undefined;
  addEvents: (
    event: string,
    callback: (event: ymaps.IEvent<MouseEvent, ymaps.Placemark>) => void
  ) => void;
  addToMap: (map: ymaps.Map) => void;
  getBounds: () => number[][];
}

const balloonOpts = {
  offset: [0, -33],
  type: 'balloon',
  shadow: true,
  autoPanMargin: 100,
  panelMaxMapArea: 0,
  closeButton: false,
  maxWidth: 600,
};

export const useStoresYandex: UseStoresType = ({
  stores,
  mapRef,
  cityCoords,
  balloonMarkup,
  mapPointer,
  fullAddress,
  setCurrentCenter,
  currentStoreId,
  setCurrentStoreId,
  actionFn,
}) => {
  type T = typeof stores[number];

  const center = [cityCoords.lat, cityCoords.lng];

  const ymaps = useYMaps(libs);
  const [map, setMap] = useState<ymaps.Map | null>(null);
  const [storesGeoCollection, setStoresGeoCollection] =
    useState<IGeoQueryResult | null>(null);
  const [balloon, setBalloon] = useState<ymaps.Balloon | null>(null);

  const setYandexMap = async () => {
    if (!ymaps || !mapRef.current) return;
    const currentMap = new ymaps.Map(
      mapRef.current,
      { behaviors, zoom, controls, center },
      options
    );
    setMap(currentMap);

    const layout = renderToStaticMarkup(
      <BallonLayout>$[[options.contentLayout]]</BallonLayout>
    );

    const balloonOptions = {
      ...balloonOpts,
      layout: ymaps.templateLayoutFactory.createClass(layout),
    };

    const mapBalloon = new ymaps.Balloon(currentMap, balloonOptions);
    mapBalloon.options.setParent(currentMap.options);
    mapBalloon.events.add('click', (e) => {
      const {
        originalEvent: { target },
      } = e;
      const { store }: { store: T } = (target as any).getData();
      const {
        originalEvent: { target: domTarget },
      } = e.get('domEvent');
      // SetTimout необходим для того, чтобы actionFn выполнялась только после завершения
      // обработчика клика. Если размонтировать карту до этого момента,
      // событие клика распространяется на элементы под картой.
      setTimeout(
        () =>
          onBalloonClick(
            domTarget,
            () => {
              if (actionFn) actionFn(store);
            },
            () => {
              mapBalloon.close();
              setCurrentStoreId(null);
            }
          ),
        0
      );
    });
    setBalloon(mapBalloon);
  };

  useEffect(() => {
    setYandexMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ymaps, mapRef]);

  const setGeoCollection = () => {
    if (!ymaps || !map || !stores.length) return;

    // const mapPointer = isUkraine ? uaMapPointerRed : mapPointerSW;
    const children = stores.reduce((acc: ymaps.Placemark[], store: T) => {
      if (!store.latitude || !store.longitude) return acc;

      // TODO Дни недели отобразить
      const storePlacemark = new ymaps.Placemark(
        [store.latitude, store.longitude],
        { store },
        {
          hideIconOnBalloonOpen: false,
          iconLayout: 'default#image',
          iconImageHref: mapPointer,
          iconImageSize: [32, 44],
        }
      );

      acc.push(storePlacemark);
      return acc;
    }, []);

    const storesCollection = new ymaps.GeoObjectCollection({
      children,
    });

    const storesQueryResult: IGeoQueryResult = (ymaps as any).geoQuery(
      storesCollection
    );
    setStoresGeoCollection(storesQueryResult);
  };

  const openBalloon = (target: ymaps.Placemark) => {
    if (!map || !balloon) return;
    const coords = target.geometry?.getCoordinates();
    if (!coords) return;
    const store = target.properties.get('store', {}) as T;

    try {
      const modifyCoords = [
        store.latitude + popupLatitudeShift,
        store.longitude,
      ];
      map.setCenter(modifyCoords);
      map.setZoom(14);

      balloon.setData({
        content: renderToStaticMarkup(balloonMarkup),
        store,
      });
      balloon.open(coords, '');
      if (currentStoreId !== store.id) {
        setCurrentStoreId(store.id);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const showSelectedStoreHandler = async (store: T) => {
    if (!map || !storesGeoCollection) return;

    const storePlacemark = storesGeoCollection.getClosestTo([
      store.latitude,
      store.longitude,
    ]);
    if (!storePlacemark) return;
    openBalloon(storePlacemark);
  };

  const onStoreClick = (event: any) => {
    if (!map) return;
    const store = event.originalEvent.target.properties?.get('store') as T;
    if (currentStoreId !== store.id) {
      setCurrentStoreId(store.id);
    }
    // showSelectedStoreHandler(store);
  };

  useEffect(() => {
    setGeoCollection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, ymaps, stores.length]);

  useEffect(() => {
    if (!map || !storesGeoCollection || !balloon) return;
    storesGeoCollection.addEvents(
      'click',
      (event: ymaps.IEvent<MouseEvent, ymaps.Placemark>) => onStoreClick(event)
    );
    storesGeoCollection.addToMap(map);

    const bounds = storesGeoCollection.getBounds();
    map.setBounds(bounds);
    if (stores.length === 1) {
      map.setZoom(15);
    }
    map.events.add('click', (e) => {
      const coords = e.get('coords');
      setCurrentCenter(coords.reverse());
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, storesGeoCollection]);

  useEffect(() => {
    if (!map || !storesGeoCollection || !balloon || !currentStoreId) return;
    const currentStore = stores.find((store) => store.id === currentStoreId);
    if (!currentStore) return;
    showSelectedStoreHandler(currentStore);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, storesGeoCollection, balloon, currentStoreId]);

  const makeRoute = async (
    from: number[],
    to: number[],
    boundToRoute = false
  ) => {
    if (!map || !ymaps) return;

    try {
      const objectsToRemove = [] as ymaps.IGeoObject<ymaps.IGeometry>[];
      map.geoObjects.each((geoObj) => {
        if (!geoObj.geometry?.getType()) objectsToRemove.push(geoObj);
      });
      objectsToRemove.forEach((geoObj) => map.geoObjects.remove(geoObj));

      const newRoute = await (ymaps as any).route([from, to]);

      const points = newRoute.getWayPoints();
      const paths = newRoute.getPaths();

      if (boundToRoute) {
        const newQuery = (ymaps as any).geoQuery(points, paths);
        await newQuery.applyBoundsToMap(map, { duration: 500, zoomMargin: 20 });
      }

      points.options.set('visible', false);
      paths.options.set({
        strokeColor: 'DA0002',
        opacity: 0.9,
      });

      map.geoObjects.add(newRoute);
    } catch (err) {
      console.log(err);
    }
  };

  const makeRouteToClosestStore = async (
    latitude: number,
    longitude: number
  ) => {
    if (!map || !ymaps || !storesGeoCollection) return;
    const from = [Number(latitude), Number(longitude)];
    const closestStore = storesGeoCollection.getClosestTo(from);
    const to = closestStore?.geometry?.getCoordinates();
    if (!closestStore || !to) return;
    await makeRoute(from, to, true);
    openBalloon(closestStore);
  };

  useEffect(() => {
    if (!storesGeoCollection || !fullAddress || !currentStoreId) return;
    const { latitude, longitude } = fullAddress;
    const currentStore = stores.find(({ id }) => currentStoreId === id);
    if (!currentStore) return;
    makeRoute(
      [Number(latitude), Number(longitude)],
      [currentStore.latitude, currentStore.longitude],
      false
    );
  }, [currentStoreId, storesGeoCollection, fullAddress]);

  useEffect(() => {
    if (!fullAddress || !storesGeoCollection) return;
    const { latitude, longitude } = fullAddress;
    makeRouteToClosestStore(Number(latitude), Number(longitude));
  }, [fullAddress, storesGeoCollection]);

  const enableScroll = () => {
    if (!map) return;
    map.behaviors.enable('scrollZoom');
  };

  return {
    enableScroll,
  };
};
