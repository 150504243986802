import { useEffect, useState } from 'react';
import {
  complectationSliceSelectors,
  resetComplectation,
  setFree,
  setPaid,
  sortCartComplectation,
} from 'src/entities/complectation/model/slice';
import { useAppDispatch, useAppSelector } from '@shared/lib/store';
import { useTranslation } from 'react-i18next';
import { getProducts } from '@entities/product/lib/useGetProducts';
import { IProduct } from 'types/product';
import { BlockContainer } from '@shared/ui/content-containers';
import { ScrollAnchor } from '@shared/ui/scroll-anchor/ScrollAnchor';
import { Subtitle } from '@shared/ui/titles';
import * as session from 'modules/session';
import { ICart, ICartItem } from 'types/cartTypes';
import ComplectationFree, {
  ICompFreeItem,
} from '../complectation-free/ComplectationFree';
import ComplectationPaid, {
  ICompPaidItem,
} from '../complectation-paid/ComplectationPaid';
import styles from './styles.module.scss';
import { complectationAnchor } from '../../model/constants';
import { IComplectationFree, IComplectationItem } from '../../types';
import { isPaidSelected, isThereFree } from '../../lib';
import { WantMoreButton } from '../want-more-button/WantMoreButton';
import { InfoButton } from '../info-btn/InfoButton';

const splitComplectation = ({
  complectations,
  cartItems,
  freeState,
}: {
  complectations: IComplectationItem[];
  cartItems: ICartItem[];
  freeState: IComplectationFree[];
}) => {
  const free: IComplectationFree[] = complectations.reduce<
    IComplectationFree[]
  >((acc, { id, max }) => {
    if (max) {
      const selectedFree = freeState.find(
        ({ product_id }) => product_id === id
      );
      acc.push({
        product_id: id,
        qty: selectedFree?.max === max ? selectedFree.qty : max,
        max,
      });
    }
    return acc;
  }, []);

  const paid = sortCartComplectation({
    items: cartItems,
    compItems: complectations,
  });
  return { free, paid };
};

function ComplectationRoot({
  isExact,
  onError,
  infoBtnAction,
}: {
  isExact: boolean;
  onError: (text: string) => void;
  infoBtnAction: () => void;
}) {
  const { complectations, items: cartItems }: ICart = session.get('cart');
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [products, setProducts] = useState<IProduct[]>([]);

  const paidState = useAppSelector(complectationSliceSelectors.selectPaid);
  const freeState = useAppSelector(complectationSliceSelectors.selectFree);

  const isFree = isThereFree(complectations || []);

  const [showPaid, setShowPaid] = useState<boolean>(
    isPaidSelected(paidState) || !isFree
  );

  useEffect(() => {
    if (!complectations?.length) {
      dispatch(resetComplectation());
      return;
    }
    const ids = complectations.map(({ id }) => id);
    getProducts(ids).then((prods) => {
      setProducts(prods);
      const filtered = complectations.filter((item) =>
        prods.find(({ id }) => id === item.id)
      );
      const sortedAvaliableComplectation = [...filtered].sort(
        (a, b) => a.position - b.position
      );
      const { free, paid } = splitComplectation({
        complectations: sortedAvaliableComplectation,
        cartItems,
        freeState,
      });
      dispatch(setFree(free));
      dispatch(setPaid(paid));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complectations, dispatch, cartItems]);

  useEffect(() => {
    if (!isPaidSelected(paidState) && !isExact && isFree) {
      setShowPaid(false);
    }
  }, [isExact, paidState, isFree]);

  if (!complectations?.length || !products.length) return null;
  const paidItems = paidState.reduce<ICompPaidItem[]>(
    (acc, { product_id, qty }) => {
      const product = products.find(({ id }) => id === product_id);
      if (product) {
        acc.push({
          product_id,
          qty,
          price: product.price,
          title: product.title,
          weight: product.weight,
          weight_type: product.weight_type,
        });
      }
      return acc;
    },
    []
  );

  const freeItems = freeState.reduce<ICompFreeItem[]>(
    (acc, { product_id, qty, max }) => {
      const product = products.find(({ id }) => product_id === id);
      if (product) {
        acc.push({
          product_id,
          qty,
          max,
          title: product?.title,
          weight: product?.weight,
          weight_type: product?.weight_type,
        });
      }
      return acc;
    },
    []
  );

  return (
    <BlockContainer>
      <header className={styles.header}>
        <ScrollAnchor
          name={complectationAnchor}
          className={styles.titleContainer}
        >
          <Subtitle>{t('Complectation.complectation')}</Subtitle>
          <InfoButton action={infoBtnAction} />
        </ScrollAnchor>
        <p className={styles.caption}>
          {t(
            freeItems.length
              ? 'Complectation.freeAndPaid'
              : 'Complectation.onlyPaid'
          )}
        </p>
      </header>

      {isFree && (
        <>
          <ComplectationFree isExact={isExact} items={freeItems} />
          <WantMoreButton
            isExact={isExact}
            show={showPaid}
            setShow={setShowPaid}
            onError={onError}
          />
        </>
      )}
      {showPaid && (
        <ComplectationPaid
          isExact={isExact}
          items={paidItems}
          onError={onError}
        />
      )}
    </BlockContainer>
  );
}

export default ComplectationRoot;
