import { INews } from '@entities/news';
import { FetchLoaderType } from '@shared/lib/routing';
import { ISeoPage } from '@widgets/seoPage';

type Params = {
  city: string;
  news_item: string;
};
export const OneNewsPageLoader = async (
  fetch: FetchLoaderType,
  { params, lang }: { params: Params; lang: string }
) => {
  const { city, news_item } = params;
  const [seoPageResponse, oneNewsPageResponse] = await Promise.all([
    fetch<ISeoPage>({
      url: '/seo/page',
      params: { url: `/news/${news_item}`, city, lang },
    }),
    fetch<{ news: INews[] }>({
      url: '/news/get',
      params: { text_id: news_item, city, lang },
    }),
  ]);
  return {
    seoPageData: seoPageResponse.data,
    oneNewsPageData: oneNewsPageResponse.data,
  };
};
