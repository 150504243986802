import { useEffect, useState } from 'react';
import * as session from 'modules/session';
import api from 'modules/helpers/api';
import globalStore from 'modules/global-store';
import { useTranslation } from 'react-i18next';
import { getProductIds } from 'modules/helpers/cart-helper';
import { IAction, ICart, IGiftSettings, IQtyState } from 'types/cartTypes';
import { IProduct } from 'types/product';
import { clsx } from 'clsx';
import { SmartLink } from '@shared/lib/routing';
import { Subtitle } from '@shared/ui/titles';
import { BlockContainer } from '@shared/ui/content-containers';
import Emitter from '../../../Emitters';
import CartPromotion from './CartPromo/CartPromotion';
import { getFilteredByProducts } from './lib';
import styles from './cartGifts.module.scss';

export type selectedState = {
  [key: number]: number;
};

// TODO: функция для получания акционных продуктов без cartProducts

// const getActionProducts = async () => {
//   const cart: ICart = session.get('cart');
//   const actionIds = getProductAndChildIds(getGiftsProducts(cart));
//   const actionProducts: IProduct[] = await api('product.get', {
//     id: actionIds,
//   });
//   return actionProducts;
// };

const getFilteredActions = async () => {
  const cart: ICart = session.get('cart');
  const { actions } = cart;
  if (!actions || actions.length === 0) return [];
  const products: IProduct[] = globalStore.get('cart_products');
  const ids = getProductIds(cart).filter(
    (id) => !products.find((prod) => prod.id === id)
  );

  const productFilter = [...products];

  if (ids.length) {
    try {
      const missingProducts: IProduct[] = await api('product.get', { id: ids });
      missingProducts.forEach((element) => productFilter.push(element));
      globalStore.set('cart_products', productFilter);
    } catch (e) {
      console.error(e);
    }
  }

  return actions.map((action) => {
    return getFilteredByProducts(action, productFilter);
  });
};

const getSelectedGifts = (cart: ICart) => {
  const { actions = [], giftSettings = {} } = cart;
  const isSelect = !!actions.find(
    ({ action: { gift_select = [] } }) => gift_select.length
  );
  if (!isSelect) return {} as selectedState;
  return actions.reduce((acc, action) => {
    const { id } = action;
    if (giftSettings[action.id] && giftSettings[action.id].select) {
      acc[id as keyof IGiftSettings] = giftSettings[id].select;
    } else if (giftSettings[id] && !giftSettings[id].disable) {
      acc[id] = 0;
    }
    return acc;
  }, {} as selectedState);
};

const fetchCartGifts = async (giftSettings: IGiftSettings) => {
  try {
    const newCart: ICart = await api('cart.gifts', { giftSettings });
    session.set('cart', newCart);

    Emitter.emit('CART_CHANGE');
    return getSelectedGifts(newCart);
  } catch (e) {
    console.error(e);
    return {};
  }
};

const selectAction = async (id: number, index: number) => {
  const { giftSettings = {} } = session.get('cart');
  if (!giftSettings[id]) giftSettings[id] = {};
  giftSettings[id].select = index;
  giftSettings[id].disable = false;
  return fetchCartGifts(giftSettings);
};

const hideAction = async ({
  id,
  defaultState,
}: {
  id: number;
  defaultState: boolean;
}) => {
  const { giftSettings = {} } = session.get('cart');
  if (!giftSettings[id]) giftSettings[id] = {};
  const actionHideState =
    typeof giftSettings[id].hide === 'boolean'
      ? giftSettings[id].hide
      : defaultState;
  giftSettings[id].hide = !actionHideState;
  return fetchCartGifts(giftSettings);
};

const disableAction = async ({ id }: { id: number }) => {
  const { giftSettings = {} } = session.get('cart');
  if (!giftSettings[id]) giftSettings[id] = {};
  giftSettings[id].disable = !giftSettings[id].disable;

  return fetchCartGifts(giftSettings);
};

const changeQtyGlobal = async (id: number, qtyState: IQtyState) => {
  const { giftSettings } = session.get('cart') as ICart;
  if (!giftSettings[id]) giftSettings[id] = { select: 0, disable: false };
  giftSettings[id].qty = qtyState;

  return fetchCartGifts(giftSettings);
};

interface ICartGifts {
  isExact: boolean;
}

function CartGifts({ isExact }: ICartGifts) {
  const { t } = useTranslation();
  const { cart } = session.get() as { cart: ICart };
  const { giftSettings = {}, recommendations = [] } = cart;
  const [selectedGifts, setSelectedGifts] = useState({} as selectedState);
  const [stocks, setStocks] = useState([] as IAction[]);

  useEffect(() => {
    const initialState = async () => {
      const filteredStocks = await getFilteredActions();
      setStocks(filteredStocks);
      setSelectedGifts(getSelectedGifts(cart));
    };

    initialState();
  }, [cart]);

  // TODO: после тестов удалить, вроде и без него всё работает

  // const onCartChange = async () => {
  //   setStocks(await getFilteredActions());
  // };

  // useEffect(() => {
  //   // TODO избавиться от этого слушателя, так как он есть у родителя
  //   //  и из за него отправляется 2 запроса('product.get')
  //   //  но без него не подтягиваютя подарки при вводе карты в корзине
  //   userEmitter.addListener('User.Login.Event', onCartChange);
  //   return () => {
  //     userEmitter.removeListener('User.Login.Event', onCartChange);
  //   };
  // }, []);

  const isRecommendations = !!recommendations.length;
  const isSomething =
    (stocks && Array.isArray(stocks) && !!stocks.length) ||
    (cart.recommendations &&
      Array.isArray(cart.recommendations) &&
      !!cart.recommendations.length);

  if (!isSomething) return null;

  return (
    <BlockContainer>
      <Subtitle>{t('BlockProducts.your_gifts')}</Subtitle>
      <ul>
        {stocks.map((stock, index) => {
          const count = index + 1;
          const { id } = stock;
          const isHidden = (giftSettings[id] && giftSettings[id].hide) || false;
          const isDisabled = giftSettings[id] && giftSettings[id].disable;
          const qtyStateInCart =
            (giftSettings[id] && giftSettings[id].qty) || {};

          return (
            <li className={styles.action} key={stock.id}>
              <CartPromotion
                count={count}
                stock={stock}
                isExact={isExact}
                selected={selectedGifts[id]}
                isHidden={isHidden}
                isDisabled={isDisabled}
                qtyStateInCart={qtyStateInCart}
                changeQtyGlobal={async (id: number, qtyState: IQtyState) =>
                  setSelectedGifts(await changeQtyGlobal(id, qtyState))
                }
                onClickHide={async (actionId: number) =>
                  setSelectedGifts(
                    await hideAction({ id: actionId, defaultState: false })
                  )
                }
                onClickDisable={async (actionId: number) =>
                  setSelectedGifts(await disableAction({ id: actionId }))
                }
                onClickSelect={async (actionId: number, index: number) =>
                  setSelectedGifts(await selectAction(actionId, index))
                }
              />
            </li>
          );
        })}
        {isRecommendations &&
          recommendations.map((recommendation, index) => {
            const { title = '', link = '', action = {} } = recommendation;
            const filteredAction = getFilteredByProducts(
              action,
              globalStore.get('cart_products')
            );
            const count = stocks.length + index + 1;
            let isHidden = true;
            if (
              giftSettings[action.id] &&
              typeof giftSettings[action.id].hide === 'boolean'
            ) {
              isHidden = giftSettings[action.id].hide || false;
            }
            const linkClassName = clsx(styles.giftLink, styles.sizeLarge, {
              [styles.noClickable]: !link,
            });
            return (
              <li
                className={styles.action}
                key={`gift-item-${action.id}-${count}`}
              >
                <div className={styles.recommendationHeader} key="gift-item">
                  {link ? (
                    <SmartLink className={linkClassName} href={link}>
                      {title}
                    </SmartLink>
                  ) : (
                    <div
                      className={linkClassName}
                      dangerouslySetInnerHTML={{ __html: title }}
                    />
                  )}
                </div>
                <div key={`gift-item-${action.id}-${count}`}>
                  <CartPromotion
                    count={count}
                    stock={filteredAction}
                    isHidden={isHidden}
                    qtyStateInCart={{}}
                    isDisabled
                    isExact
                    onClickHide={(actionId: number) =>
                      hideAction({
                        id: actionId,
                        defaultState: true,
                      })
                    }
                  />
                </div>
              </li>
            );
          })}
      </ul>
    </BlockContainer>
  );
}

export default CartGifts;
