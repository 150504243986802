import { IComplectationItem, IComplectationPaid } from '../types';
import useDebouncer from './useDebouncer';

const isThereFree = (complectation: IComplectationItem[]) => {
  return !!complectation.filter(({ max }) => max).length;
};

const isPaidSelected = (paid: IComplectationPaid[]) => {
  return paid.some(({ qty }) => qty);
};

export { isThereFree, isPaidSelected, useDebouncer };
