import autobind from 'autobind-decorator';
import { Component, createRef } from 'react';
import { filterPanelEmitter } from 'modules/event-emitters';
import { withTranslation } from 'react-i18next';
import globalStore from 'modules/global-store';
import { clsx } from 'clsx';
import { withCheckCountries } from '@entities/countries';
import { Button } from '@shared/ui/button';
import { RemoveScroll } from 'react-remove-scroll';
import Price from './Price/Price';
import Popular from './Popular/Popular';
import Attributes from './Attributes/Attributes';
import Tag from './Tag/Tag';
import Composition from './Composition/Composition';
import './FilterPanel.scss';

class FilterPanel extends Component {
  panelRef = createRef();

  static getAttributes(products) {
    const tags = {};
    let compositions = {};

    products.forEach((item) => {
      item.tags.forEach((tag) => {
        if (!tags[tag.tag_id]) {
          tags[tag.tag_id] = tag;
        }
      });

      item.composition.forEach((composition) => {
        if (!compositions[composition.composition_id]) {
          compositions[composition.composition_id] = composition;
        }
      });
    });

    let _compositions = [];
    for (const i in compositions) {
      _compositions.push(compositions[i]);
    }

    _compositions = _compositions.sort((a, b) => {
      if (a.title.trim() < b.title.trim()) return -1;
      if (a.title.trim() > b.title.trim()) return 1;

      return 0;
    });

    compositions = {};

    _compositions.forEach((c) => {
      compositions[`s${c.composition_id}`] = c;
    });

    return { tags, compositions };
  }

  constructor(props) {
    super(props);
    this.state = {
      close: true,
      filtersModalClose: true,
    };
  }

  componentDidMount() {
    this.updateHeaderHight();

    window.addEventListener('resize', this.updateHeaderHight);

    globalStore.set('__FilterPanelDidMount', true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.menu_id === this.props.menu_id) {
      return;
    }

    document.body.classList.remove('hidden');
    this.setState({
      close: true,
      filtersModalClose: true,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateHeaderHight);
    sessionStorage.removeItem('__FilterPanelDidMount');
    globalStore.set('__FilterPanelDidMount', false);
  }

  @autobind
  getVisibleFilters(filters) {
    const visibleFilters = {};
    for (const filter in filters) {
      if (filters[filter].visible) {
        visibleFilters[filter] = filters[filter];
      }
    }
    return visibleFilters;
  }

  @autobind
  updateHeaderHight() {
    const header = document.getElementById('main-header');
    const headerHight = header && header.offsetHeight;

    this.setState({
      headerHight,
    });
  }

  @autobind
  toggle() {
    if (this.state.close) {
      const headerFilterPanel = this.panelRef.current;
      const rect = headerFilterPanel.getBoundingClientRect();
      const distanceToTop = rect.top;
      const headerFilterPanelHight =
        headerFilterPanel && headerFilterPanel.offsetHeight;
      const hightForFilterContent = distanceToTop + headerFilterPanelHight;

      this.setState({
        hightForFilterContent,
      });
    }
    this.setState((prevState) => ({ close: !prevState.close }));
  }

  @autobind
  resetAll() {
    filterPanelEmitter.emit('reset_all');
  }

  @autobind
  toggleFiltersModal() {
    let { filtersModalClose } = this.state;
    filtersModalClose = !filtersModalClose;
    this.setState({ filtersModalClose });
  }

  render() {
    const { tags, compositions } = FilterPanel.getAttributes(
      this.props.products
    );
    const { close, filtersModalClose, headerHight, hightForFilterContent } =
      this.state;
    const { attributes, checkedTags, checkedComps, sorts, t } = this.props;
    let activeAttributesLength = 0;
    Object.keys(attributes).map(function (key) {
      if (attributes[key]) {
        activeAttributesLength++;
      }
    });

    let activeSortsLength = 0;
    Object.keys(sorts).map(function (key) {
      if (sorts[key] > 0) {
        activeSortsLength++;
      }
    });
    const totalSelectedCount =
      activeSortsLength +
      activeAttributesLength +
      checkedTags.length +
      checkedComps.length;
    const filtersAndSortingsCount =
      activeSortsLength + activeAttributesLength + checkedTags.length;
    const visibleFilters = this.getVisibleFilters(tags);

    const isUa = this.props.isUkraine;
    const titleArrow = clsx('title__arrow', { down: close, up: !close });
    const filterPanelContent = clsx('filter-panel__content', { close });
    const filterPanelBottom = clsx('filter-panel__bottom', { close });
    const groupHover = clsx('group__hover', { active: !filtersModalClose });
    const filters = clsx('group__content filters_and_sorting', {
      close: filtersModalClose,
    });

    return (
      <RemoveScroll
        enabled={!close}
        ref={this.panelRef}
        className="filter-panel"
        style={{ top: headerHight }}
      >
        <div className="filter-panel__title" onClick={this.toggle}>
          <div
            className={clsx('title__icon', { 'title__ua-filter-icon': isUa })}
          />
          <div className="title__text">{`${t(
            'FilterPanel.Filters_and_sorting'
          )} ${totalSelectedCount ? `(${totalSelectedCount})` : ''}`}</div>
          <div className={titleArrow} />
        </div>
        <div
          className={filterPanelContent}
          style={{
            top: hightForFilterContent,
          }}
        >
          <div className="content__group">
            <div className={groupHover} onClick={this.toggleFiltersModal} />
            <div className="group__title" onClick={this.toggleFiltersModal}>
              {`${t('FilterPanel.Filters_and_sorting')} ${
                filtersAndSortingsCount ? `(${filtersAndSortingsCount})` : ''
              }`}
            </div>
            <div className={filters}>
              <div className="content__block">
                <div className="block__title">{t('FilterPanel.Sorting')}</div>
                <Popular />
                <Price />
              </div>
              <div className="content__block">
                <div className="block__title">{t('FilterPanel.Filters')}</div>
                <Attributes attributes={attributes} />
              </div>
              <Tag tags={visibleFilters} checkedTags={checkedTags} />
            </div>
          </div>
          <div className="content__group">
            <div className="group__content">
              <div className="content__block">
                <div className="block__title">
                  {t('FilterPanel.Ingredients')}
                </div>
                <Composition
                  compositions={compositions}
                  checkedComps={checkedComps}
                />{' '}
              </div>
            </div>
          </div>
        </div>
        <div className={filterPanelBottom}>
          <Button
            onClick={this.resetAll}
            disabled={`${totalSelectedCount === 0 ? 'disabled' : ''}`}
          >
            {t('FilterPanel.reset')}
          </Button>
          <Button onClick={this.toggle}>{t('FilterPanel.apply')}</Button>
        </div>
      </RemoveScroll>
    );
  }
}

export default withTranslation()(withCheckCountries(FilterPanel));
