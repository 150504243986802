import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import WokSauceImage from 'assets/images/wok-box/wok_sauce.png';
import vegetablesImage from 'assets/images/wok-box/vegetables.unmin.jpg';
import DynamicImageWokBox from 'frontend/Components/DynamicImageWokBox';
import { useCheckCountries } from '@entities/countries';
import { clsx } from 'clsx';
import { Subtitle } from '@shared/ui/titles';
import { BlockContainer } from '@shared/ui/content-containers';
import { InternalImage } from '@shared/ui/image';
import NoImgRadioButton from './NoImgRadioButton';
import ToppingOptionButton from './ToppingOptionButton';
import ConstructorWidget from '../../ConstructorWidget/ConstructorWidget';

function PlusIcon() {
  return (
    <div className="card-wok__icon-container">
      <i className="card-wok__icon">+</i>
    </div>
  );
}

function Bases({ children, t }) {
  return (
    <div className="card-wok__item-wrapper">
      <div className="card-wok__item item-options-img">
        <p className="card-wok__name">{t('CardWok.choose_basis')}</p>
        <ul className="card-wok__list">{children}</ul>
      </div>
    </div>
  );
}

function BaseRadioButton({ base, selected, onChange, t }) {
  const { isUkraine } = useCheckCountries();

  return (
    <li className="card-wok__option">
      <div className="card-wok__input-wrapper">
        <input
          id={base.id}
          name={base.type}
          type="radio"
          value={base.id}
          checked={selected}
          onChange={onChange}
          className={clsx('sw-radio', 'card-wok__radio', { ua: isUkraine })}
        />
        <label htmlFor={base.id} className="card-wok__label">
          {base.title}
        </label>
      </div>
      <div className="card-wok__img-option-container">
        <DynamicImageWokBox
          urlChunk={base.text_id}
          className="card-wok__img-option"
          alt={t('CardWok.wok_ingredients')}
          style={{ margin: '0 auto' }}
        />
      </div>
    </li>
  );
}

function Vegetables({ children, t }) {
  return (
    <div className="card-wok__item-wrapper">
      <div className="card-wok__item vegetables">
        <p className="card-wok__name">{t('CardWok.vegetables')}</p>
        {children[0]}
        <div className="card-wok__text-container">
          <p className="card-wok__text">{t('CardWok.ingredients')}</p>
        </div>
        <InternalImage
          width={220}
          height={119}
          src={vegetablesImage}
          alt={t('CardWok.vegetables')}
          isCenter
        />
      </div>
      {children[1]}
    </div>
  );
}

function Sauces({ children, t }) {
  return (
    <div className="card-wok__item-wrapper">
      <div className="card-wok__item">
        <p className="card-wok__name">{t('CardWok.chooseSauce')}</p>
        <ul className="card-wok__list">{children}</ul>
        <InternalImage
          src={WokSauceImage}
          width={271}
          height={84}
          alt={t('CardWok.wok_sauce')}
          isCenter
        />
      </div>
    </div>
  );
}

function BuyButton({ onClickBuy, t }) {
  const { isUkraine } = useCheckCountries();
  return (
    <button
      type="button"
      className={clsx('card__button', { ua: isUkraine })}
      onClick={onClickBuy}
    >
      {t('localization.Add to card')} <span className="card__button-add" />
    </button>
  );
}

function TotalPrice({ price, currency, t }) {
  return (
    <div className="card-wok__total-container">
      <span className="card-wok__total-text">
        {`${t('CardWok.Total_sum')}: ${price} ${currency}`}
      </span>
    </div>
  );
}

function ToppingsAdditionals({ type, children, t }) {
  return (
    <div className="card-wok__item-wrapper additional">
      <div className="card-wok__item">
        <p className="card-wok__name">{t(`CardWok.${[type]}`)}</p>
        <ul className="card-wok__list">{children}</ul>
        <DynamicImageWokBox
          urlChunk={type}
          alt={t('CardWok.wok_ingredients')}
          style={{ margin: '0 auto' }}
        />
      </div>
    </div>
  );
}

class WokConstructorDesktop extends Component {
  render() {
    const {
      currency,
      constructor,
      onSelectBase,
      onSelectSauce,
      onSelectVegetables,
      onChangeNumberOfToppings,
      onToggleTopping,
      onAddToCart,
      t,
    } = this.props;

    const ToppingsCheckboxes = ({
      constructor,
      onChangeCheckboxHandler,
      onSelectHandler,
      currency,
      t,
    }) =>
      constructor.collectToppingsDividedByGroups(
        ([groupName, toppingsFromGroup]) => (
          <ToppingsAdditionals
            type={groupName}
            key={`topping-${groupName}`}
            t={t}
          >
            {toppingsFromGroup.map((topping) => (
              <ToppingOptionButton
                toppingSelected={constructor.isToppingSelected(topping)}
                disabled={constructor.canNotAddNewToppingsFor(groupName)}
                name={`toppings-${groupName}-${topping.id}`}
                onChangeCheckboxHandler={onChangeCheckboxHandler}
                topping={topping}
                maxAvailableCount={constructor.maxNumberOfTopping(topping)}
                onSelectHandler={onSelectHandler}
                currentCount={constructor.numberOfTopping(topping)}
                currency={currency}
                key={`toppings-${groupName}-${topping.id}`}
              />
            ))}
          </ToppingsAdditionals>
        )
      );

    return (
      <BlockContainer>
        <Subtitle>{t('CardWok.assemble_wok')}</Subtitle>
        <div className="card-wok__wrapper">
          <section className="card-wok">
            <div className="card-wok__top">
              <div className="card-wok__container">
                <Bases t={t}>
                  {constructor.collectBases((base) => (
                    <BaseRadioButton
                      t={t}
                      base={base}
                      selected={constructor.isBaseSelected(base)}
                      onChange={onSelectBase}
                      key={base.id}
                    />
                  ))}
                </Bases>
                <PlusIcon />
                <Vegetables t={t}>
                  {constructor.collectVegetables
                    ? constructor.collectVegetables(
                        (vegetables, index, arr) => {
                          if (arr.length < 2) return;
                          return (
                            <NoImgRadioButton
                              item={vegetables}
                              selected={constructor.isVegSelected(vegetables)}
                              onChange={onSelectVegetables}
                              key={vegetables.id}
                            />
                          );
                        }
                      )
                    : undefined}
                  <TotalPrice
                    t={t}
                    price={constructor.totalPrice}
                    currency={currency}
                  />
                </Vegetables>
                <PlusIcon />
                <Sauces t={t}>
                  {constructor.collectSauces((sauce) => (
                    <NoImgRadioButton
                      item={sauce}
                      selected={constructor.isSauceSelected(sauce)}
                      onChange={onSelectSauce}
                      key={sauce.id}
                    />
                  ))}
                </Sauces>
              </div>
            </div>
            <div className="card-wok__additional">
              <div className="card-wok__title-container">
                <h2 className="card-wok__title">{t('CardWok.additional')}</h2>
              </div>
              <div className="card-wok__container last">
                {ToppingsCheckboxes({
                  constructor,
                  currency,
                  onSelectHandler: onChangeNumberOfToppings,
                  onChangeCheckboxHandler: onToggleTopping,
                  t,
                })}
              </div>
            </div>
          </section>
          <ConstructorWidget
            type="wok"
            constructor={constructor}
            currency={currency}
            button={<BuyButton t={t} onClickBuy={onAddToCart} />}
          />
        </div>
      </BlockContainer>
    );
  }
}

export default withTranslation()(WokConstructorDesktop);
